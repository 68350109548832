import React from "react";
import navPic from './images/navPic.jpg';
import styles from './css/Navcomponent.module.css';
import {IoMdMail} from 'react-icons/io';
import {FaFacebookSquare, FaInstagramSquare, FaLinkedin} from 'react-icons/fa';
import InitParams from "./config/InitParams.json";

class NavComponent extends  React.Component{
    render() {
        console.log(this.props.isNavOpen);
        return(
            <div className={`${styles.navBox} ${this.props.isNavOpen? styles.open :""}`}>
                <img alt={"Profile Pic"} src={navPic} className={styles.navPic}/>
                <div>
                    <h1>Kribhan Maharjan</h1>
                </div>
                <div className={"iconAndLabelContainer"}>
                    <span className={"icon"}><IoMdMail/></span>
                    <span className={`iconLabel ${styles.iconLabelMod}`}>maharjankribhan@gmail.com</span>
                </div>
                {this.props.navItems.map(item=>{
                    return (<a href="#" onClick={()=>{this.props.onClick(item.key)}} className={`${styles.navItem} ${this.props.selectedItem === item.key && styles.selected}`}><div>{item.value}</div></a>);
                })}
                <a href={"#"} className={styles.navIconItem}><FaFacebookSquare size={30}/></a>
                <br/>
                <a href={"#"} className={styles.navIconItem}><FaInstagramSquare size={30}/></a>
                <br/>
                <a href={"#"} className={styles.navIconItem}><FaLinkedin size={30}/></a>
                <div className={styles.navBottom}> Love + Code + Photography = Life</div>
            </div>
        )
    }
}

export default NavComponent;
