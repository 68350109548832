import React from "react";
import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import {MdWork, MdSchool} from 'react-icons/md';
import {GiDirectionSigns} from 'react-icons/gi';
import styles from '../css/Profession.module.css';

export default class Profession extends React.Component{
    render(){
        var blueArrowColor = "#abdcf3";
        return(
            <div className={styles.container}>
                <VerticalTimeline className={styles.timeline}>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--education"
                        textClassName={styles.blueTimelineItem}
                        date="2018 - 2020"
                        iconClassName={styles.blueTimelineIcon}
                        dateClassName={`${styles.timelineDate} ${styles.blueTimelineDate}`}
                        icon={<MdSchool />}
                    >
                        <h3 className="vertical-timeline-element-title">Master of Information and Communication Technology</h3>
                        <h4 className="vertical-timeline-element-subtitle">La Trobe University</h4>
                        <h5 className="vertical-timeline-element-subtitle">Melbourne, VIC, Australia</h5>
                        <p>
                            Network Engineering | Wireless Network Engineering | Network & Web Security
                        </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        textClassName={styles.greenTimelineItem}
                        date="2014 - 2018"
                        iconClassName={styles.greenTimelineIcon}
                        dateClassName={`${styles.timelineDate} ${styles.greenTimelineDate}`}
                        icon={<MdWork />}
                    >
                        <h3 className="vertical-timeline-element-title">Senior Software Engineer</h3>
                        <h4 className="vertical-timeline-element-subtitle">Verscend Technologies</h4>
                        <h5 className="vertical-timeline-element-subtitle">Kathmamdu, Nepal</h5>
                        <p>
                            Microservices development | Data Visualization | FrontEnd UI development | Team Leading
                        </p>
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className="vertical-timeline-element--education"
                        textClassName={styles.blueTimelineItem}
                        date="2018 - 2020"
                        iconClassName={styles.blueTimelineIcon}
                        dateClassName={`${styles.timelineDate} ${styles.blueTimelineDate}`}
                        icon={<MdSchool />}
                    >
                        <h3 className="vertical-timeline-element-title">Bachelor of Computer Engineering</h3>
                        <h4 className="vertical-timeline-element-subtitle">Kathmandu University</h4>
                        <h5 className="vertical-timeline-element-subtitle">Dhulikhel, Nepal</h5>
                        <p>
                            Artificial Intelligence | Cloud Computing | Software Engineering | Data Structures, Algorithms & Complexity
                        </p>
                    </VerticalTimelineElement>


                    <VerticalTimelineElement
                        iconClassName={styles.greenTimelineIcon}
                        icon={<GiDirectionSigns />}
                    />
                </VerticalTimeline>
            </div>
        );
    }
}
