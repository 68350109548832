import React from "react"
import styles from "../css/About.module.css";
import {FaCloudDownloadAlt, FaGithub} from "react-icons/all";

class About extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            slideIndex:-1
        };
    }
    render(props){
        var slideIndex = this.state.slideIndex;
        return (
            <div className={styles.container}>
                <div className={`${slideIndex===0? (styles.show+' '+styles.fade): styles.hide}`}>
                    <h1 className={styles.headerText}> Hi! I am Kribhan.</h1>
                    <a href={"static/resume.pdf"}>
                        <div className={`${styles.borderedSpan} ${styles.alignedRight} iconAndLabelContainer`}>
                            <span className={`iconLabel ${styles.iconLabelMod}`}>Download My Resume</span>
                            <span className={`icon ${styles.iconMod}`}><FaCloudDownloadAlt size={30}/></span>
                        </div>
                    </a>
                </div>
                <div className={`${slideIndex===1? (styles.show+' '+styles.fade): styles.hide}`}>
                    <h1 className={styles.headerText}> I love to code.</h1>
                    <a href ="#">
                        <div className={`${styles.borderedSpan} ${styles.alignedRight} iconAndLabelContainer`}>
                            <span className={`iconLabel ${styles.iconLabelMod}`}>Checkout my work</span>
                            <span className={`icon ${styles.iconMod}`}><FaGithub size={30}/></span>
                        </div>
                    </a>
                </div>
            </div>
        );
    }
    componentDidMount() {
        this.changeSlides();
    }
    changeSlides(){
        this.setState({
            slideIndex: (this.state.slideIndex+1)%2
        });
        setTimeout(()=>this.changeSlides(this), 5000);
    }
}

export default About;
