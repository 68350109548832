import React from 'react';
import styles from '../css/Hamburger.module.css';

export default class Hamburger extends React.Component{

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.state = {
            isHamburgerStateOpen : false
        };
    }

    handleClick(){
        if(this.props.onClick){
            this.props.onClick();
        }
        this.setState({
            isHamburgerStateOpen : !this.state.isHamburgerStateOpen
        });
    }
    render() {
        return(
            <div className={`${styles.hamburgerContainer} ${this.state.isHamburgerStateOpen? styles.open : ""}`} onClick={this.handleClick}>
                <div className={styles.div1}></div>
                <div className={styles.div2}></div>
                <div className={styles.div3}></div>
            </div>
        );
    }
}
