import React from "react";
import NavComponent from './NavComponent.js';
import styles from './css/App.module.css';
import About from "./Compomnents/About";
import Profession from "./Compomnents/Profession";
import InitParams from "./config/InitParams.json";
import Hamburger from './Compomnents/Hamburger.js';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            navState: {
                selectedItem: InitParams.defaultNavItem
            },
            isNavOpen:false
        }
        this.navItemClickHandler = this.navItemClickHandler.bind(this);
        this.handleHamburgerClick = this.handleHamburgerClick.bind(this);
    }

    navItemClickHandler(itemKey) {
        this.setState({
            navState: {
                selectedItem: itemKey
            },
            isNavOpen:false
        });
    }

    handleHamburgerClick(){
        console.log("Changing state to "+!this.state.isNavOpen);
        this.setState({
            isNavOpen:!this.state.isNavOpen
        });
    }
    render() {
        return (
            <div className={styles.App}>
                <Hamburger onClick={this.handleHamburgerClick}/>
                <NavComponent selectedItem={this.state.navState.selectedItem}
                              isNavOpen = {this.state.isNavOpen}
                              navItems={InitParams.navItems} onClick={this.navItemClickHandler}/>
                {this.state.navState.selectedItem === InitParams.navItems[0].key && <About/>}
                {this.state.navState.selectedItem === InitParams.navItems[1].key && <Profession/>}
            </div>
        );
    }
}

export default App;
